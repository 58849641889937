/* eslint-disable @angular-eslint/no-host-metadata-property */

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input, numberAttribute } from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor, ThemeSize } from '@fmnts/components';

export type ButtonFill = 'clear' | 'outline' | 'solid';
export type ButtonShape = 'rounded' | 'pill';

@Directive({
  host: {
    '[class.fmnts-button]': 'true',
    '[attr.disabled]': 'disabled || null',
    '[attr.aria-disabled]': 'disabled.toString()',
    // User-specified tabindex is ignored when disabled for
    // consistency with native buttons where even though they
    // have an index, they're not tabbable.
    '[attr.tabindex]': 'disabled ? -1 : tabIndex',
  },
})
export class ButtonBaseComponent {
  @HostBinding('class.fmnts-button') protected readonly componentClass =
    'fmnts-button';

  /**
   * Set to `true` to disable the button.
   *
   * **Notice:** On `<a />` tags this won't disable the navigation,
   * especially when used with `[routerLink]`. The correct way to disable
   * the navigation would be
   * ```html
   * <a
   *  [routerLink]="disabled ? null : [...]"
   *  [disable]="disabled"
   * >Link</a>
   * ```
   */
  @Input()
  @HostBinding('class.fmnts-button--disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  /** Fill variant */
  @Input() fill: ButtonFill = 'clear';

  /** Size of the button */
  @Input() size: ThemeSize = 'md';

  /** Color variant to use for the button */
  @Input() color?: ThemeColor;

  /** Tabindex of the button. */
  @Input({ transform: numberAttribute }) tabIndex?: number;

  @HostBinding('class') get hostClasses(): string {
    return classnames([
      // Variant: color
      this.color && `fmnts-button--${this.color}`,
      // Variants: size
      `fmnts-button--${this.size}`,
      // Variants: fill style
      `fmnts-button--${this.fill ?? 'solid'}`,
    ]);
  }
}
