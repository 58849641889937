import { Version } from '@angular/core';
import { LogLevel } from '@fmnts/common/log';
import { IAppEnvironment } from './environment.type';

export const environment: IAppEnvironment = {
  production: true,
  name: 'prod',
  version: new Version('14.14.0'),

  log: {
    console: { level: LogLevel.Warn },
    idb: {
      level: LogLevel.Debug,
      driver: { maxEntries: 100 },
    },
  },
  zendesk: {
    accountUrl: 'formunauts.zendesk.com',
  },
};
