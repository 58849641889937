import { Injectable, inject } from '@angular/core';
import { ApiConfigService } from '@fmnts/api';
import { AuthClientConfig } from '@fmnts/api/auth';
import { isNonEmptyString } from '@fmnts/core';
import { Settings } from './settings';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly apiConfigService = inject(ApiConfigService);
  private readonly authConfig = inject(AuthClientConfig);

  private _settings: Settings;

  public get settings() {
    return this._settings;
  }
  public set settings(value: Settings) {
    this._settings = value;
    this.apiConfigService.apiUrl = value?.apiUrl ?? '';
    this.authConfig.clientId = value?.oauthClientId ?? '';
  }

  // TODO: This might move if we add a setting to the cockpit user.
  // To support English users, having Monday as the first day of the week with
  // moment.js, we need to manually set the first day of the week, otherwise
  // people using the English locale would automatically have Sunday as the
  // first day of the week. 0 = Sunday, 1 = Monday, 2 = Tuesday,...
  public get firstDayOfWeek() {
    return 1;
  }

  // Only Europe uses calendar weeks, so it's important to have this setting be
  // the same for everyone. According to ISO 8601, week 1 is the week containing
  // the first Thursday of the year (see numbering scheme from firstDayOfWeek).
  public get firstDayOfYear() {
    return 4;
  }

  /**
   * Set of URLs that can be added as preconnect URLs.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/preconnect
   */
  public get preconnectUrls(): string[] {
    return [this.settings.assetUrl]
      .filter(isNonEmptyString)
      .map((url) => new URL(url).origin);
  }

  public get raiseNowTestMode(): boolean {
    return !!(
      this.settings &&
      this.settings.raiseNowTestMode &&
      this.settings.raiseNowTestMode.toLowerCase() === 'true'
    );
  }
}
