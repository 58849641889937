import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiRequestHelper } from '@fmnts/api/util';
import { isUndefined } from '@fmnts/core';
import { Observable, map } from 'rxjs';
import { LoqateApiClientConfigService } from '../loqate-api-config.service';
import {
  ErrorResponse,
  LoqateApiError,
  LoqateUnknownApiError,
  isErrorResponse,
} from '../shared/loqate-shared.model';
import {
  IApiLoqateInteractiveValidateQuery,
  InteractiveValidateQuery,
  ValidationResponse,
} from './loqate-bank-validation.api-model';

@Injectable({
  providedIn: 'root',
})
export class LoqateBankValidationApi {
  private readonly http = inject(HttpClient);
  private readonly apiConfig = inject(LoqateApiClientConfigService);
  private readonly apiHelper = inject(ApiRequestHelper);

  private static readonly endpointPrefix =
    'https://api.addressy.com/BankAccountValidation/Interactive';

  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'ngsw-bypass': 'true',
  });

  /**
   * @returns
   * Object `ValidationResponse`, containing Validity Information from Loqate
   */
  public validate(
    query: InteractiveValidateQuery,
  ): Observable<ValidationResponse> {
    const url = this.endpointUrl(['Validate/v2.00/json3.ws']);
    const params = this.parseParams(
      this._mapToApiInteractiveValidateQuery(query),
    );

    return this.http
      .post<{ Items: ValidationResponse[] | [ErrorResponse] }>(url, params, {
        headers: this.httpHeaders,
      })
      .pipe(
        map((response) => {
          const item = response.Items[0];
          if (isUndefined(item)) {
            throw new LoqateUnknownApiError(item);
          }
          if (isErrorResponse(item)) {
            throw new LoqateApiError(item);
          }

          return item;
        }),
      );
  }

  private _mapToApiInteractiveValidateQuery(
    query: InteractiveValidateQuery,
  ): IApiLoqateInteractiveValidateQuery {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      AccountNumber: query.accountNumber,
      SortCode: query.sortCode,
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private parseParams(
    object:
      | HttpParamsOptions['fromObject']
      | IApiLoqateInteractiveValidateQuery,
  ): HttpParams {
    return this.apiHelper.makeParams({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Key: this.apiConfig.bankValidation.apiKey,
      ...object,
    });
  }
  /**
   * @param parts Parts of the url that should be joined
   *
   * @returns
   * Complete URL for the given parts
   */
  private endpointUrl(parts: (string | number)[]): string {
    const url = [LoqateBankValidationApi.endpointPrefix, ...parts].join('/');

    return url;
  }
}
